import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.regexp.split";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { GroupIndex, AuthGroupSave, AuthGroupRead, AuthGroupDelete, AuthGroupUpdate, getGroupUsers } from '@/api/updateUserInfo/structure';
import { AuthRuleRuleList } from '@/api/updateUserInfo/users';
import DisplaySettings from '@/components/DisplaySettings/index';
import Head from '@/components/head/index';
export default {
  name: 'nxframe',
  data: function data() {
    return {
      //系统教程
      tab: 0,
      course: this.$route.query.course,
      prohibit: false,
      //系统教程
      title: '岗位权限',
      titles: '',
      treeSelect: [],
      loading: true,
      dialogFormVisible: false,
      tableData: [],
      dialogPvVisible: false,
      pvData: [],
      treeId: '',
      tempId: '',
      ClassSearch: '',
      //搜索岗位
      vals: 'newly',
      rules: {
        name: [{
          required: true,
          message: '请输入必填项',
          trigger: 'change'
        }]
      },
      Datas: [],
      defaultProps: {
        children: 'children',
        label: 'title'
      },
      jurisdiction: 0,
      is_encrypt_mobile: 1
    };
  },
  created: function created() {},
  mounted: function mounted() {
    if (this.course != 1) {
      this.getList();
    } else {}
  },
  computed: {
    userId: function userId() {
      return this.$store.state.user.id;
    }
  },
  props: ['routerPath'],
  watch: {},
  components: {
    DisplaySettings: DisplaySettings,
    Head: Head
  },
  methods: {
    selectable: function selectable(row, index) {
      if (row.is_admin == 1) {
        return false;
      } else {
        return true;
      }
    },
    getList: function getList(val) {
      var _this = this;
      //栏目列表
      this.loading = true;
      GroupIndex({
        group_name: this.ClassSearch
      }).then(function (respomse) {
        _this.tableData = respomse.data;
        _this.loading = false;
      });
    },
    resetTemps: function resetTemps() {
      //清空公告弹窗
      this.titles = '';
      this.tempId = '';
      this.$refs.tb.clearSelection();
    },
    showStaff: function showStaff(id) {
      var _this2 = this;
      //人员列表
      this.dialogPvVisible = true;
      var param = {
        id: id
      };
      getGroupUsers(param).then(function (res) {
        _this2.pvData = res.data;
      });
    },
    handleSelectionChange: function handleSelectionChange(val) {
      for (var i = 0; i < val.length; i++) {
        this.tempId = val[i].id;
      }
      if (val.length > 1) {
        this.$refs.tb.clearSelection();
        this.$refs.tb.toggleRowSelection(val.pop());
      }
    },
    handleAddRole: function handleAddRole(val) {
      var _this3 = this;
      AuthRuleRuleList().then(function (respomse) {
        respomse.data.map(function (e) {
          e.label = e.title;
        });
        _this3.Datas = respomse.data;
      });
      this.vals = val;
      if (val == 'newly') {
        this.dialogFormVisible = true;
        this.resetTemps();
      } else if (val == 'edit') {
        if (this.tempId != '') {
          this.dialogFormVisible = true;
          AuthGroupRead({
            id: this.tempId
          }).then(function (res) {
            var that = _this3;
            _this3.titles = res.data.group.title;
            _this3.jurisdiction = res.data.group.data_authority;
            _this3.is_encrypt_mobile = res.data.group.is_encrypt_mobile;
            var aaa = res.data.group.rules.split(',').map(function (res) {
              return res;
            });
            setTimeout(function () {
              aaa.forEach(function (value) {
                that.$refs.tree.setChecked(value, true, false);
              });
            }, 500);
            _this3.treeId = aaa;
          });
        } else {
          this.$message({
            type: 'info',
            message: '请选择岗位'
          });
        }
      }
    },
    ClassSearchInput: function ClassSearchInput(val) {
      this.getList();
    },
    treeCk: function treeCk() {
      this.treeId = this.$refs.tree.getCheckedKeys().concat(this.$refs.tree.getHalfCheckedKeys());
    },
    createData: function createData() {
      var _this4 = this;
      var data = {
        title: this.titles,
        rules: String(this.treeId),
        data_authority: this.jurisdiction,
        is_encrypt_mobile: this.is_encrypt_mobile
      };
      if (this.titles == '') {
        this.$message({
          type: 'info',
          message: '请输入岗位'
        });
      } else {
        if (this.treeId == '') {
          this.$message({
            type: 'info',
            message: '请选择至少一条权限'
          });
        } else {
          if (this.is_encrypt_mobile === '') {
            this.$message({
              type: 'info',
              message: '请选择电话是否加密'
            });
            return;
          }
          this.dialogFormVisible = false;
          if (this.vals == 'newly') {
            AuthGroupSave(data).then(function (res) {
              _this4.getList();
              _this4.resetTemps();
              _this4.$notify({
                title: '成功',
                message: '新建成功',
                type: 'success',
                duration: 2000
              });
            });
          } else {
            AuthGroupUpdate({
              title: this.titles,
              rules: String(this.treeId),
              id: this.tempId,
              data_authority: this.jurisdiction,
              is_encrypt_mobile: this.is_encrypt_mobile
            }).then(function (res) {
              _this4.getList();
              _this4.resetTemps();
              _this4.$notify({
                title: '成功',
                message: '编辑成功',
                type: 'success',
                duration: 2000
              });
            });
          }
        }
      }
    },
    cancel: function cancel() {
      this.dialogFormVisible = false;
      this.$refs.tb.clearSelection();
    },
    deleteDepartment: function deleteDepartment() {
      var _this5 = this;
      if (this.tempId != '') {
        this.$confirm('此操作将永久删除此岗位, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          AuthGroupDelete({
            id: _this5.tempId
          }).then(function (respomse) {
            _this5.getList();
            _this5.$notify({
              title: '成功',
              message: '删除成功',
              type: 'success',
              duration: 2000
            });
          });
        }).catch(function () {
          _this5.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      } else {
        this.$message({
          type: 'info',
          message: '请选择一个岗位'
        });
      }
    },
    checkChange: function checkChange(a, b, c) {
      // const anode = this.$refs.tree.getNode(a)
      // !anode.checked ? this.findchildren(anode.parent) : ''
    },
    findchildren: function findchildren(current) {
      var fnode = this.$refs.tree.getNode(current);
      if (!fnode.isLeaf) {
        fnode.indeterminate = true;
        fnode.checked = true;
      }
    }
  }
};