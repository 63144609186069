var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container postAuthority" }, [
    _vm.course != 1
      ? _c(
          "div",
          { staticStyle: { width: "100%" } },
          [
            _c(
              "el-header",
              { staticStyle: { height: "80px" } },
              [
                _c(
                  "el-row",
                  { staticStyle: { height: "50px" }, attrs: { gutter: 24 } },
                  [_c("Head", { attrs: { name: _vm.title } })],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  { attrs: { gutter: 24 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 13 } },
                      [
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: ["新建"],
                                expression: "['新建']",
                              },
                            ],
                            staticClass: "btnse",
                            attrs: { type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.handleAddRole("newly")
                              },
                            },
                          },
                          [_vm._v("新建")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: ["编辑"],
                                expression: "['编辑']",
                              },
                            ],
                            staticClass: "btnse",
                            attrs: { type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.handleAddRole("edit")
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: ["删除"],
                                expression: "['删除']",
                              },
                            ],
                            staticClass: "btnse",
                            attrs: { type: "primary" },
                            on: { click: _vm.deleteDepartment },
                          },
                          [_vm._v("删除")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { staticStyle: { float: "right" }, attrs: { span: 4 } },
                      [
                        _c(
                          "el-input",
                          {
                            staticClass: "input-with-select",
                            attrs: {
                              placeholder: "岗位名称搜索",
                              size: "small",
                            },
                            on: { change: _vm.ClassSearchInput },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.ClassSearchInput($event)
                              },
                            },
                            model: {
                              value: _vm.ClassSearch,
                              callback: function ($$v) {
                                _vm.ClassSearch = $$v
                              },
                              expression: "ClassSearch",
                            },
                          },
                          [
                            _c("el-button", {
                              attrs: {
                                slot: "append",
                                type: "primary",
                                size: "small",
                                icon: "el-icon-search",
                              },
                              on: { click: _vm.ClassSearchInput },
                              slot: "append",
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-main",
              { staticStyle: { "margin-top": "10px" } },
              [
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loading,
                          expression: "loading",
                        },
                      ],
                      ref: "tb",
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.tableData,
                        border: "",
                        "header-cell-style": {
                          background: "#F9F9F9",
                          color: "#222222",
                        },
                      },
                      on: { "selection-change": _vm.handleSelectionChange },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          type: "selection",
                          align: "center",
                          selectable: _vm.selectable,
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "title",
                          label: "岗位",
                          align: "center",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "user_num",
                          label: "成员数",
                          align: "center",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "create_time",
                          label: "添加时间",
                          align: "center",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "查看人员列表",
                          width: "180",
                          align: "center",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: {
                                        background: "#1682e6",
                                        color: "#fff",
                                      },
                                      attrs: { size: "mini" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.showStaff(scope.row.id)
                                        },
                                      },
                                    },
                                    [_vm._v("人员列表")]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3743241041
                        ),
                      }),
                    ],
                    1
                  ),
                ],
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "el-dialog",
              {
                staticClass: "big-dialog",
                attrs: {
                  title: _vm.vals == "newly" ? "新建岗位" : "编辑岗位",
                  visible: _vm.dialogFormVisible,
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.dialogFormVisible = $event
                  },
                  close: _vm.resetTemps,
                },
              },
              [
                _c(
                  "el-form",
                  {
                    staticStyle: { width: "500px", "margin-left": "50px" },
                    attrs: {
                      rules: _vm.rules,
                      "label-position": "right",
                      "label-width": "120px",
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "角色名", prop: "titles" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "角色名称" },
                          model: {
                            value: _vm.titles,
                            callback: function ($$v) {
                              _vm.titles = $$v
                            },
                            expression: "titles",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "菜单" } },
                      [
                        _c("el-tree", {
                          ref: "tree",
                          attrs: {
                            data: _vm.Datas,
                            "show-checkbox": "",
                            "node-key": "id",
                            props: _vm.defaultProps,
                            "check-strictly": false,
                          },
                          on: {
                            "check-change": _vm.checkChange,
                            check: _vm.treeCk,
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "数据权限", prop: "jurisdiction" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.jurisdiction,
                              callback: function ($$v) {
                                _vm.jurisdiction = $$v
                              },
                              expression: "jurisdiction",
                            },
                          },
                          [
                            _c(
                              "el-radio",
                              { attrs: { label: 0 } },
                              [
                                _vm._v(
                                  "\n                            本人\n                            "
                                ),
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      effect: "dark",
                                      content: "只能看到本人的数据",
                                      placement: "top-start",
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "el-icon-info",
                                      staticStyle: {
                                        color: "#606266",
                                        "padding-left": "6px",
                                      },
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-radio",
                              { attrs: { label: 1 } },
                              [
                                _vm._v(
                                  "本人及其下属\n                            "
                                ),
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      effect: "dark",
                                      content: "只能看到本人及其所有下属的数据",
                                      placement: "top-start",
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "el-icon-info",
                                      staticStyle: {
                                        color: "#606266",
                                        "padding-left": "6px",
                                      },
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-radio",
                              { attrs: { label: 2 } },
                              [
                                _vm._v("全部\n                            "),
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      effect: "dark",
                                      content: "可以看到所有的数据",
                                      placement: "top-start",
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "el-icon-info",
                                      staticStyle: {
                                        color: "#606266",
                                        "padding-left": "6px",
                                      },
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "电话是否加密",
                          prop: "is_encrypt_mobile",
                        },
                      },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.is_encrypt_mobile,
                              callback: function ($$v) {
                                _vm.is_encrypt_mobile = $$v
                              },
                              expression: "is_encrypt_mobile",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 1 } }, [
                              _vm._v("是"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 0 } }, [
                              _vm._v("否"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "dialog-footer",
                    attrs: { slot: "footer" },
                    slot: "footer",
                  },
                  [
                    _c("el-button", { on: { click: _vm.cancel } }, [
                      _vm._v("取消"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.createData },
                      },
                      [_vm._v("确认")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-dialog",
              {
                attrs: { visible: _vm.dialogPvVisible, title: "人员列表" },
                on: {
                  "update:visible": function ($event) {
                    _vm.dialogPvVisible = $event
                  },
                },
              },
              [
                _c(
                  "el-table",
                  {
                    staticStyle: { width: "100%" },
                    attrs: {
                      data: _vm.pvData,
                      border: "",
                      fit: "",
                      "highlight-current-row": "",
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: { type: "index", label: "序号", width: "50" },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { prop: "realname", label: "姓名" },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { label: "状态" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  _vm._s(
                                    Number(scope.row.positive_status)
                                      ? "已转正"
                                      : "未转正"
                                  )
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        262892290
                      ),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { label: "是否有效" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  _vm._s(
                                    Number(scope.row.status) ? "正常" : "禁用"
                                  )
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3015167388
                      ),
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "dialog-footer",
                    attrs: { slot: "footer" },
                    slot: "footer",
                  },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            _vm.dialogPvVisible = false
                          },
                        },
                      },
                      [_vm._v("确认")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      : _c(
          "div",
          {
            staticClass: "boxImg",
            staticStyle: { width: "100%", padding: "20px" },
          },
          [_c("system", { attrs: { title: _vm.title, tab: _vm.tab } })],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }